<template>
  <div class="viewEdit">
    <b-card class="mb-0">
      <!--view edit header -->
      <div class="card-header-edit">
        <p class="title">
          Timesheet Status
        </p>
        <div class="card-header-second mt-0" />
        <Help
          v-show="$store.state.app.userManualList"
          code="TimesheetStatus"
        />
      </div>
    </b-card>
    <div class="card-filter-section">
      <b-row>
        <b-col
          cols="8"
          lg="9"
          class="d-block d-lg-flex align-items-center justify-content-start flex-wrap"
        >
          <p class="filter-title">
            Filters:
          </p>
          <custom-dropdown
            v-model="searchStatus"
            :options="status"
            label="Status"
            multiple
            icon="EditIcon"
          />
          <OptionDropdown v-model="perPage" />
        </b-col>
        <b-col
          cols="4"
          lg="3"
          style="text-align: end"
        >
          <button
            class="apply-filter filterRedesign mr-1"
            @click="ApplyFilter()"
          >
            Apply Filter
          </button>
          <button
            class="apply-filter-clear filterRedesign"
            @click="clearFilter()"
          >
            Clear
          </button>
        </b-col>
      </b-row>
    </div>
    <div
      v-if="timeSheetData.length"
      class="emp-list"
    >
      <b-row>
        <b-col>
          <b-table
            :items="timeSheetData"
            :fields="fields"
            show-empty
            responsive
            class="scrollable-element emp-list-data approvel-table tableDesigns"
          >
            <template #cell(duration)="data">
              <div class="d-flex align-items-center">
                <span>{{ data.item.total_time | convertMinutes }}</span>
              </div>
            </template>

            <template #cell(week)="data">
              <div class="d-flex align-items-center">
                <span>{{
                  new Date(data.item.start_date).toLocaleDateString("en-us", {
                    weekday: "short",
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })
                }}
                  -
                  {{
                    new Date(data.item.end_date).toLocaleDateString("en-us", {
                      weekday: "short",
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })
                  }}</span>
              </div>
            </template>

            <template #cell(status)="data">
              <div class="d-flex align-items-center">
                <span
                  v-if="data.item.approval_status == 'pending'"
                  :class="
                    data.item.approval_status == 'pending'
                      ? 'text-black-50'
                      : ''
                  "
                >
                  {{ data.item.approval_status }}
                </span>
                <span
                  v-if="data.item.approval_status == 'partial_approved'"
                  :class="
                    data.item.approval_status == 'pending'
                      ? 'text-black-50'
                      : ''
                  "
                >
                  Partial Approved
                </span>
                <span
                  v-if="data.item.approval_status == 'approved'"
                  :class="
                    data.item.approval_status == 'approved'
                      ? 'text-success'
                      : ''
                  "
                >
                  {{ data.item.approval_status }}
                </span>
                <span
                  v-if="data.item.approval_status == 'rejected'"
                  :class="
                    data.item.approval_status == 'rejected' ? 'text-danger' : ''
                  "
                >
                  {{ data.item.approval_status }}
                </span>
                <span
                  v-if="data.item.approval_status == 'in_process'"
                  :class="
                    data.item.approval_status == 'in_process'
                      ? 'text-warning'
                      : ''
                  "
                >
                  Submitted
                </span>
              </div>
            </template>
            <template #cell(rejection_reason)="data">
              <div class="align-items-center d-inline-block">
                <p
                  class="timesheetReportDesc"
                  style="word-break: keep-all;text-transform:none"
                >
                  {{
                    data.item.rejection_reason ? data.item.rejection_reason : ""
                  }}
                </p>
              </div>
            </template>
            <template #cell(action)="data">
              <span style="cursor: pointer">
                <feather-icon
                  icon="EyeIcon"
                  size="18"
                  class="emp-eyeicon"
                  @click="viewDailyTimeSheet(data.item)"
                />
              </span>
            </template>
          </b-table>
        </b-col>

      </b-row>

      <!-- pagination section -->
      <Pagination
        v-if="totalCount>0"
        :current-page="currentPage"
        :per-page="perPage"
        :total-count="totalCount"
        @currentPage="currentPage = $event"
      />
      <!-- pagination section complete here -->

    </div>
    <div
      v-else
      class="w-100"
    >
      <img
        src="@/assets/images/nodatafound/nodata.svg"
        alt="no-data"
        class="no-data-img"
      >
    </div>
  </div>
</template>
<script>

import customDropdown from '@/components/dropdown/customDropdown.vue'
import OptionDropdown from '../../components/optiondropdown/OptionDropdown.vue'

export default {
  name: 'ApprovalsStatus',
  components: {
    customDropdown,
    OptionDropdown,

  },
  data() {
    return {
      fields: [
        { key: 'week', label: 'week', tdClass: 'emp-data' },
        { key: 'duration', label: 'duration', tdClass: 'emp-data' },
        { key: 'status', label: 'status', tdClass: 'status' },
        { key: 'rejection_reason', label: 'reason', tdClass: 'status' },
        {
          key: 'action', label: 'action', tdClass: 'status text-center', thClass: 'text-center',
        },
      ],
      timeSheetData: [],
      status: [
        { label: 'Pending', value: 'pending' },
        { label: 'Approved', value: 'approved' },
        { label: 'Rejected', value: 'rejected' },
        { label: 'Submitted', value: 'in_process' },
      ],
      searchStatus: [],
      perPage: 100,
      currentPage: 1,
      totalCount: 0,
    }
  },
  watch: {
    currentPage() {
      this.getTimeSheetStatus()
    },
    perPage() {
      if (this.currentPage === 1) {
        this.getTimeSheetStatus()
      } else {
        this.currentPage = 1
      }
    },
  },
  mounted() {
    this.setFilterValue()
    this.getTimeSheetStatus()
  },
  methods: {
    /**
     * Get getTimeSheetStatus
     */
    async getTimeSheetStatus() {
      this.loader = true
      const input = {
        approval_status: this.searchStatus,
        per_page: this.perPage,
        page: this.currentPage,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/timesheet/user-all-timesheet',
        input,
        false,
      )
      if (response && response.data) {
        const data = response.data.timesheet
        //
        this.timeSheetData = data
        this.totalCount = response.data.count
      }
      this.loader = false
    },
    ApplyFilter() {
      this.currentPage = 1
      this.queryUpdate()
      this.getTimeSheetStatus()
    },
    clearFilter() {
      this.searchStatus = []
      this.perPage = 100
      this.currentPage = 1
      this.getTimeSheetStatus()
    },

    setFilterValue() {
      if (this.$route.query) {
        this.searchStatus = this.$route.query.status ? this.$route.query.status == 'all' ? [] : Array.isArray(this.$route.query.status) ? this.$route.query.status : [this.$route.query.status]
          : this.searchStatus
        this.perPage = this.$route.query.perPage
          ? this.$route.query.perPage
          : 100
        this.currentPage = this.$route.query.currentPage
          ? this.$route.query.currentPage
          : 1
      }
    },

    queryUpdate() {
      const query = {
        status: this.searchStatus && this.searchStatus.length ? this.searchStatus : 'all', currentPage: this.currentPage, perPage: this.perPage,
      }

      this.routeQueryUpdate(query)
    },

    viewDailyTimeSheet(data) {
      this.$store.state.app.timeSheetRejectDates.start_date = data.start_date

      this.$store.state.app.timeSheetRejectDates.end_date = data.end_date

      this.$router.push({ name: 'timeSheetView' })
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/component-css/approvals.css";
@import "../../assets/scss/component-css/vieweditheader.scss";
@import "../../assets/scss/component-css/viewedit.scss";

.emp-list .table .status {
  min-width: 100px;
  max-width: 400px;
}

</style>
